import React, { useState, useEffect, useMemo } from "react"
import AuctionPropertySearchSales from "@templates/auction-search-results-template";
import PastAction from "@templates/past-action-template";
import Layout from "@components/layout";
import {
  parseSearchUrl,
  savedSearchParams,
  propertyH1
} from "@components/common/site/utils";
const SearchResult = (props) => {
  const fullpath = props.location.pathname
  const fullpathone = props.location

  let auctionId = null;
  let splitPath = fullpath?.split("/");
  let category = splitPath.length > 3 ? splitPath[3]: "";
  // if (category) {
    if (category?.includes("auction")) {
      auctionId = category.replace("-auction", "")
    }
  // }
  // console.log('auctionDate', auctionDate, splitPath, category.includes("previous-auction-results"))
  if (auctionId) {
    return (<AuctionPropertySearchSales locationname={`/property-auctions/previous-auction-results/${auctionId}-auction/`}
      auctionType={"past"} auctionId={auctionId} pcategorytype="auction" ptype="sales" ptypetag="for-sale" pstatustype={["Sold", "SoldPrior", "Unsold", "SoldPost", "Withdrawn", "NoBids", "ReferToAuctioneer", "PostPoned"]} fullpathone={fullpathone} fullpathname={fullpath} />
    );
  } else {
    return (<PastAction />)
  }


};

export default SearchResult;
