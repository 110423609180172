import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"
import { Link } from "gatsby"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import logoBlack from "../images/svg/logo.svg"
import Card from "../components/card/card"
import moment from "moment"
import _ from "lodash";

const PastAction = (props) => {



  const [propItems, setPropItems] = useState([])
  const path = {
    pathname: `/property-auctions/previous-auction-results/`
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        baseURL: process.env.GATSBY_STRAPI_FORM_URL,
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          // "Content-Type": "multipart/form-data"  
        }
      })
      console.log("propItems", data)
      setPropItems(_.orderBy(_.uniqBy(data, 'AuctionDate'), ['AuctionDate'], 
      ['desc']))
    } catch (error) {
      // console.error(error)
    }
  }
  useEffect(() => {
    // let url = `https://strakers-strapi-dev.q.starberry.com/auction/listauctiondate`;
    let url = `${process.env.GATSBY_STRAPI_SRC}/auction/listauctiondate`;

    getitems(url)
  }, []);
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }

  return propItems?.length > 0 ? (
    <Layout
    >



      <Seo
        title={`Past Action`}
        location={path}
        description={`Past Action`}
        image={null}
      />
      <Helmet
        bodyAttributes={{
          class: `past-action-page`,
        }}
      />

      <Breadcrumbs Page={`Past Auctions`} alias={"previous-auction-results"} type={"common-page"} />
      <div className="bg-linear">
        {/* <TeamDetailInfo data={Page} /> */}
        <div className={"home-properties pt-120"}>
          <Container className="home-properties__inner">
            <Row className="align-items-center align-items-lg-start justify-content-lg-between">
              <Col xs={12} lg="auto">
                <h3>Past Auctions</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="home-properties__inner category-list">
          <Row className="align-items-baseline">
            {propItems?.map((item, i) => {
              return (
                <Col md={6} lg={3}>
                  <Card >
                    <Link to={`/property-auctions/previous-auction-results/${item?.AuctionId}-auction/`}  
                    //  onClick={() => {
                    //       props.setAuctionDate(item?.AuctionDate)
                    //     }}
                        >

                      <div className="overflow-hidden">
                     
                          {item.AuctionImage &&
                          <img src={item.AuctionImage} alt={`Auction Image`} className="auction-image"/>
                          }
                      
                      </div>
                    </Link>
                    <Card.Body>
                      <Link className="address-min-height"
                        to={`/property-auctions/previous-auction-results/${item?.AuctionId}-auction/`}
                        // onClick={() => {
                        //   props.setAuctionDate(item?.AuctionDate)
                        // }}
                      >
                        <h5>{moment(item?.AuctionDate).format("MMMM YYYY")} Auction</h5>
                      </Link>
                    </Card.Body>
                    <Card.Footer>
                      <Row className="g-0 flex-nowrap justify-content-between">
                        <Col>
                          <Link className="link-icon blue-link-hover d-flex align-items-center justify-content-start"
                            to={`/property-auctions/previous-auction-results/${item?.AuctionId}-auction/`}
                            // onClick={() => {
                            //   props.setAuctionDate(item?.AuctionDate)
                            // }}
                          >
                            <div
                              className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                            >
                              Details
                            </div>
                            <i className={classNames(`icon icon--eye`)}></i>
                          </Link>
                          {/* <LinkIcon text="Details" icon="eye" /> */}
                        </Col>
                      </Row>
                    </Card.Footer>

                  </Card>
                </Col>
              )
            })}

          </Row>
        </Container>
      </div>

    </Layout>
  ) : (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

}

export default PastAction

